body,html {
    margin: 0;
    padding: 0;
}

#root {
    box-sizing: border-box;
    height: 100vh;
    padding: 0;
    /* Header */
    padding-top: 64px;
}

@media (max-width: 600px) {
    #root {
        padding-top: 56px;
    }
  }